.payment-brands {
  display: grid;
  width: fit-content;
  margin: auto;
  border-radius: 11px 11px 0 0;
  background: rgba(0, 0, 55, 0.35);
  backdrop-filter: blur(15px);

  &__body {
    position: relative;
    display: grid;
    grid-template: 1fr / repeat(6, 1fr);
    gap: 0 var(--v-offset-sm);
    padding: calc(var(--v-offset-sm) / 2) var(--v-offset-sm);
    height: 100%;

    @include min(tablet-portrait) {
      padding: calc(var(--v-offset-sm) / 3) var(--v-offset-sm);
    }

    .svg-icon {
      position: relative;
      z-index: 1;
      width: 100%;
      max-width: 34px;
      aspect-ratio: 10 / 5.4;
      fill: White;

      @include min(phone-landscape) {
        max-width: 52px;
        fill: White;
      }
    }
  }
}
