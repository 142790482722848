:root {
  // z-orders
  --z-index-header: 1;
  --z-index-dialog-lite: 999;

  // colors
  --c-white: rgb(255 255 255);

  // NPM package typographics
  --max-vw: 1600;
  --min-vw: 600;
  --max-fs: 1;
  --min-fs: .6;
  --font-family-base: "Inter", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --line-height-base: 1.5;
  --line-height-heading: 1.3em;

  // scrollbar colors
  --c-scrollbar-track: Black;
  --c-scrollbar-thumb: DarkGray;
  --c-scrollbar-hover: Brown;

  // offsets
  --v-offset-sm: min(50px, 3vmax);
  --v-offset-md: min(100px, 5vmax);
  --v-offset-lg: min(160px, 8vmax);

  @media (width >= 992px) {
    --v-offset-sm: min(40px, 2vmax);
    --v-offset-md: min(80px, 4vmax);
  }
}
