.your-gain {
  position: relative;
  display: grid;
  grid-template: auto / 1fr 1fr;
  gap: 0;

  &__title {
    grid-row: 1 / 2;
    grid-column: 1 / 3;
    margin-bottom: clamp(15px, 2.5vmin, 25px);
    filter: drop-shadow(0 0 clamp(5px, 1vmin, 20px) rgba(0, 0, 0, 0.75));
    opacity: 0;
    transition: opacity 425ms 225ms ease-in-out;

    body.first-spin-complete & {
      opacity: 1;
    }
  }

  &__bonus-first {
    grid-row: 2 / 3;
    grid-column: 1 / 2;
    justify-self: end;
    filter: drop-shadow(0 0 clamp(5px, 1vmin, 20px) rgba(0, 0, 0, 0.75));
    transition: transform 1s ease-in-out, opacity 525ms ease-in-out;

    @include min(tablet-landscape) {
      justify-self: start;

      transform: translateX(50%);
      opacity: 0;

      body.first-spin-complete & {
        transform: translateX(0);
        opacity: 1;
      }
    }

    @include max(tablet-landscape) {
      transform: translateX(50%);
      opacity: 0;

      body.first-spin-complete & {
        opacity: 1;
      }

      body.second-spin-complete & {
        transform: translateX(-10px);
      }
    }
  }

  &__bonus-second {
    grid-row: 2 / 3;
    grid-column: 2 / 3;
    justify-self: start;
    filter: drop-shadow(0 0 clamp(5px, 1vmin, 20px) rgba(0, 0, 0, 0.75));
    transition: transform 1s ease-in-out, opacity 525ms ease-in-out;

    @include min(tablet-landscape) {
      justify-self: end;

      transform: translateX(-50%);
      opacity: 0;

      body.second-spin-complete & {
        transform: translateX(0);
        opacity: 1;
      }
    }

    @include max(tablet-landscape) {
      transform: translateX(-50%);
      opacity: 0;

      body.second-spin-complete & {
        transform: translateX(10px);
        opacity: 1;
      }
    }
  }

  &__stars {
    position: absolute;
    inset: 0;
    margin: auto;
    width: fit-content;
    height: fit-content;

    @include max(tablet-landscape) {
      body:not(.first-spin-complete) & {
        transform: rotate(-7deg);
        animation: libration 750ms infinite alternate ease-in-out;

        @-webkit-keyframes libration {
          to {
            transform: rotate(7deg);
          }
        }
      }
    }

    .svg-icon {
      width: 90px;
      height: 90px;
      transition: transform 225ms ease-in-out, opacity 225ms ease-in-out;

      &:first-child {
        position: absolute;
        inset: 0;
        margin: auto;

        body.first-spin-complete & {
          transform: translateX(-150px);
        }

        body.second-spin-complete & {
          opacity: 0;
        }
      }

      &:last-child {
        body.first-spin-complete & {
          transform: translateX(150px);
        }

        body.second-spin-complete & {
          opacity: 0;
        }
      }
    }
  }
}
