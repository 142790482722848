html,
body {
  position: relative;
}

body {
  overflow: hidden auto;
  width: 100vw;
  background-image: url(../images/page_bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 0;

  @include min(tablet-landscape) {
    background-position: 50% 0;
  }

  @supports (min-height: 100dvh) {
    min-height: 100dvh;
  }

  @supports not (min-height: 100dvh) {
    min-height: 100vh;
  }

  @include min(tablet-landscape) {
    &::before {
      content: '';
      position: absolute;
      inset: 0;
      margin: auto;
      background-image:
        url(../images/coin_two.png),
        url(../images/coin_one.png),
        url(../images/coin_three.png),
        url(../images/banknote_two.png),
        url(../images/banknote_three.png),
        url(../images/banknote_one.png),
        url(../images/woman_two.png),
        url(../images/woman_one.png);
      background-size:
        300px 300px,
        220px 240px,
        200px 240px,
        240px 240px,
        240px 240px,
        240px 240px,
        min(34.5vw, 690px) min(42vw, 840px),
        min(34.5vw, 690px) min(42vw, 840px);
      background-position:
        33vw -140px,
        83vw -20px,
        89vw 60vh,
        calc(70% + 390px) calc(50% + 8vh),
        calc(30% - 390px) calc(50% + 8vh),
        calc(45% - 390px) calc(50% + 8vh),
        calc(60% + 390px) calc(50% + 8vh),
        calc(40% - 390px) calc(50% + 8vh);
      background-repeat:
        no-repeat,
        no-repeat,
        no-repeat,
        no-repeat,
        no-repeat,
        no-repeat,
        no-repeat,
        no-repeat;
    }
  }

  @include max(tablet-landscape) {
    &::before {
      content: '';
      position: absolute;
      inset: 0;
      margin: auto;
      background-image:
        url(../images/coin_two.png),
        url(../images/coin_one.png),
        url(../images/coin_three.png),
        url(../images/woman_one.png),
        url(../images/woman_two.png);
      background-size:
        160px 160px,
        220px 240px,
        160px 160px,
        280px 340px,
        280px 340px;
      background-position:
        calc(100% + 60px) -60px,
        calc(100% + 80px) calc(100% + 100px),
        -70px 60vh,
        -110px 42%,
        calc(100% + 110px) 42%;
      background-repeat:
        no-repeat,
        no-repeat,
        no-repeat,
        no-repeat,
        no-repeat;
    }
  }
}
