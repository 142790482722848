.dialog-lite {
  overflow: hidden;

  &--in {
    overflow: hidden auto;
  }
}

.modal-content {
  margin: auto;
  width: min(420px, 100%);

  &__bait {
    cursor: pointer;

    .dialog-lite--second & {
      display: none;
    }
  }

  &__form {
    overflow: hidden;
    padding: 15px;
    background-color: #fff;
    border-radius: 22px;

    .dialog-lite--first & {
      display: none;
    }
  }
}
