@mixin wheel-fortune-positioning {
  position: absolute;
  inset: 0;
  margin: auto;
  border-radius: 100%;
}

.wheel-fortune {
  --z-index-segments: 1;
  --z-index-blurring: 2;
  --z-index-blackout: 3;
  --z-index-button: 4;
  --z-index-border: 5;

  position: relative;
  width: min(480px, (100% - 40px));
  aspect-ratio: 1;
  margin: auto;

  @include min(tablet-landscape) {
    width: min(540px, 100%);
  }

  &::after {
    @include wheel-fortune-positioning;

    pointer-events: none;
    bottom: auto;
    z-index: 6;
    content: '';
    width: 64px;
    height: 64px;
    transform: translateY(-64%);
    border-radius: unset;
    background: url(../images/wheel_arrow.png) center / cover no-repeat;
    filter: drop-shadow(0 0 clamp(5px, 1vmin, 20px) rgb(0, 0, 0));

    @include min(phone-portrait) {
      width: 88px;
      height: 88px;
    }
  }

  &::before {
    @include wheel-fortune-positioning;

    inset: -7%;
    pointer-events: none;
    z-index: 6;
    content: '';
    border-radius: unset;
    background: url(../images/wheel_frame.png) center / cover no-repeat;
    filter: drop-shadow(0 0 clamp(5px, 1vmin, 20px) rgba(0, 0, 0, 1));
  }

  &__segments {
    @include wheel-fortune-positioning;

    z-index: var(--z-index-segments);
    overflow: hidden;
    will-change: transform;
    pointer-events: none;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  &__decor {
    height: 100%;
    pointer-events: none;

    &::after {
      @include wheel-fortune-positioning;

      z-index: var(--z-index-blurring);
      content: '';
      transform: translate3d(0, 0, 0);
      backdrop-filter: blur(var(--blurring));
      will-change: transform;
      backface-visibility: hidden;
      pointer-events: none;
    }

    &::before {
      --cutout: calc(100% - (100% / var(--blackout-angle, 8)));
      --color: rgba(0, 0, 0, var(--blackout-opacity, 0));

      @include wheel-fortune-positioning;

      z-index: var(--z-index-blackout);
      content: '';
      background: conic-gradient(var(--color) var(--cutout), transparent var(--cutout));
      transform: rotate(calc((360deg / var(--blackout-angle, 8)) / 2));
      pointer-events: none;
    }
  }

  &__button {
    @include wheel-fortune-positioning;

    z-index: var(--z-index-button);
    width: 30%;
    aspect-ratio: 1;
    background-image: url(../images/wheel_button.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    cursor: pointer;
    transition: filter 475ms ease;
    filter: drop-shadow(0 0 clamp(5px, 0.75vmin, 15px) rgba(0, 0, 0, 0.75)) brightness(1);
  }

  &.is-spinning,
  &.end-last-spin {
    .wheel-fortune__button {
      pointer-events: none;
      filter: drop-shadow(0 0 clamp(5px, 0.75vmin, 15px) rgba(0, 0, 0, 0.75)) brightness(0.35);
    }
  }
}
