:root {
  --max-vw: 1440;
  --min-vw: 600;
  --max-fs: 1;
  --min-fs: .75;
  --min-fs-rem: calc(var(--min-fs) * 1rem);
  --max-fs-rem: calc(var(--max-fs) * 1rem);
  --min-vw-rem: calc((var(--min-vw) / 10) * 1rem);
  --slope: calc((var(--max-fs)  - var(--min-fs)) * (100vw - var(--min-vw-rem)) / ((var(--max-vw) / 10)  - (var(--min-vw) / 10)));
  --font-size-clamp: clamp(var(--min-fs-rem), calc(var(--min-fs-rem)  + var(--slope)), var(--max-fs-rem));
  --line-height-base: 1.5;
  --line-height-heading: 1.3em;
}

html {
  font-feature-settings: "kern" 1, "liga" 0;
  text-rendering: optimizelegibility;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  text-size-adjust: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  font-size: 10px;
}

body {
  font-family: var(--font-family-base, "Inter");
  font-size: var(--font-size-clamp);
  line-height: var(--line-height-base);
  font-weight: 400;
}

a {
  -webkit-text-decoration-skip-ink: auto;
  text-decoration-skip-ink: auto;
}

.display-large {
  font-size: 5.7em;
  line-height: var(--line-height-heading);
  margin-top: calc(var(--line-height-heading) / 2 * 2.5);
  margin-bottom: calc(var(--line-height-heading) / 2 * 1);
  text-wrap: balance;
  max-inline-size: 50ch;
  margin-left: -.08em;
}

.display-medium {
  font-size: 4.5em;
  line-height: var(--line-height-heading);
  margin-top: calc(var(--line-height-heading) / 2 * 2.5);
  margin-bottom: calc(var(--line-height-heading) / 2 * 1);
  text-wrap: balance;
  max-inline-size: 50ch;
  margin-left: -.07em;
}

.display-small {
  font-size: 3.6em;
  line-height: var(--line-height-heading);
  margin-top: calc(var(--line-height-heading) / 2 * 2.5);
  margin-bottom: calc(var(--line-height-heading) / 2 * 1);
  text-wrap: balance;
  max-inline-size: 50ch;
  margin-left: -.07em;
}

.headline-large {
  font-size: 3.2em;
  line-height: var(--line-height-heading);
  margin-top: calc(var(--line-height-heading) / 2 * 2.5);
  margin-bottom: calc(var(--line-height-heading) / 2 * 1);
  text-wrap: balance;
  max-inline-size: 50ch;
  margin-left: -.06em;
}

.headline-medium {
  font-size: 2.8em;
  line-height: var(--line-height-heading);
  margin-top: calc(var(--line-height-heading) / 2 * 2.5);
  margin-bottom: calc(var(--line-height-heading) / 2 * 1);
  text-wrap: balance;
  max-inline-size: 50ch;
  margin-left: -.06em;
}

.headline-small {
  font-size: 2.4em;
  line-height: var(--line-height-heading);
  margin-top: calc(var(--line-height-heading) / 2 * 2.5);
  margin-bottom: calc(var(--line-height-heading) / 2 * 1);
  text-wrap: balance;
  max-inline-size: 50ch;
  margin-left: -.05em;
}

.title-large {
  font-size: 2.2em;
  line-height: var(--line-height-heading);
  margin-top: calc(var(--line-height-heading) / 2 * 2.5);
  margin-bottom: calc(var(--line-height-heading) / 2 * 1);
  text-wrap: balance;
  max-inline-size: 50ch;
  margin-left: -.04em;
}

.title-medium {
  font-size: 1.6rem;
  line-height: var(--line-height-heading);
  margin-top: calc(var(--line-height-heading) / 2 * 2.5);
  margin-bottom: calc(var(--line-height-heading) / 2 * 1);
  text-wrap: balance;
  max-inline-size: 50ch;
  margin-left: -.03em;
  font-weight: 600;
}

.title-small {
  font-size: 1.4rem;
  line-height: var(--line-height-heading);
  margin-top: calc(var(--line-height-heading) / 2 * 2.5);
  margin-bottom: calc(var(--line-height-heading) / 2 * 1);
  text-wrap: balance;
  max-inline-size: 50ch;
  font-weight: 600;
}

.label-large {
  text-wrap: balance;
  margin-bottom: 1rem;
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 2rem;
}

.label-medium {
  text-wrap: balance;
  margin-bottom: .8rem;
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 1.6rem;
}

.label-small {
  text-wrap: balance;
  margin-bottom: .8rem;
  font-size: 1.1rem;
  font-weight: 600;
  line-height: 1.6rem;
}

.body-large {
  text-wrap: balance;
  margin-bottom: 1.2rem;
  font-size: 1.6rem;
  line-height: 2.4rem;
}

.body-medium {
  text-wrap: balance;
  margin-bottom: 1rem;
  font-size: 1.4rem;
  line-height: 2rem;
}

.body-small {
  text-wrap: balance;
  margin-bottom: .8rem;
  font-size: 1.2rem;
  line-height: 1.6rem;
}

ul, ol {
  padding-left: 0;
  font-size: 1.4rem;
  line-height: 2rem;
  list-style-position: outside;
}

.dialog-lite {
  z-index: var(--z-index-dialog-lite, 992);
  width: 100vw;
  position: fixed;
  inset: 0;
  overflow: clip auto;
}

.dialog-lite--in {
  -webkit-overflow-scrolling: touch;
}

.dialog-lite--out {
  pointer-events: none;
}

.dialog-lite__backdrop {
  z-index: var(--z-index-dialog-lite-backdrop, 993);
  margin: auto;
  position: fixed;
  inset: 0;
}

.dialog-lite--in .dialog-lite__backdrop {
  background-color: #0c0c13d1;
  transition: background-color .4s cubic-bezier(.61, 1, .88, 1);
}

.dialog-lite--out .dialog-lite__backdrop {
  pointer-events: none;
  background-color: #0f0f1000;
  transition: background-color .5s cubic-bezier(0, 0, .5, 1);
}

.dialog-lite__container {
  pointer-events: none;
  z-index: var(--z-index-dialog-lite-container, 994);
  place-content: center;
  width: 100vw;
  display: grid;
  position: relative;
}

@supports (min-height: 100dvh) {
  .dialog-lite__container {
    min-height: 100dvh;
  }
}

@supports not (min-height: 100dvh) {
  .dialog-lite__container {
    min-height: 100vh;
  }
}

.dialog-lite__container-inner {
  margin: 20px;
  position: relative;
}

.dialog-lite--in .dialog-lite__container-inner {
  pointer-events: auto;
  opacity: 1;
  transition: opacity .4s cubic-bezier(.61, 1, .88, 1), transform .4s cubic-bezier(.61, 1, .88, 1);
  transform: translateY(0);
}

.dialog-lite--out .dialog-lite__container-inner {
  pointer-events: none;
  opacity: 0;
  transition: opacity .5s cubic-bezier(0, 0, .5, 1), transform .55s cubic-bezier(.22, 1, .5, .95);
  transform: translateY(40px);
}

.dialog-lite-close-button {
  cursor: pointer;
  place-content: center;
  width: 50px;
  height: 50px;
  display: grid;
  position: absolute;
  inset: 0 0 auto auto;
}

.dialog-lite-close-button .svg-icon {
  fill: #000;
  width: 24px;
  height: 24px;
}

.text-field-container {
  --tf-c-text-field: slategrey;
  --tf-c-text-helper: slategrey;
  --tf-c-inactive: darkgray;
  --tf-c-focused: dodgerblue;
  --tf-c-activated: gray;
  --tf-c-hover: dimgray;
  --tf-c-error: crimson;
  --tf-c-disabled: silver;
  --tf-c-placeholder: silver;
  --tf-c-border: var(--tf-c-inactive);
  --tf-font-size-field: 16px;
  --tf-font-size-helper: 12px;
  --tf-font-weight: 400;
  --tf-letter-spacing: .009375em;
  --tf-input-line-height: 23px;
  --tf-textarea-line-height: 24px;
  --tf-border-width-inactive: 1px;
  --tf-border-width-active: 2px;
  --tf-border-radius: 6px;
  --tf-transition-easing: cubic-bezier(0, .202, .204, 1);
  flex-direction: column;
  display: flex;
}

.notched-outline {
  z-index: 1;
  text-align: left;
  pointer-events: none;
  width: 100%;
  max-width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  left: 0;
  right: 0;
}

.notched-outline__leading {
  pointer-events: none;
  border-top: var(--tf-border-width-inactive) solid;
  border-right: none;
  border-bottom: var(--tf-border-width-inactive) solid;
  border-left: var(--tf-border-width-inactive) solid;
  border-radius: var(--tf-border-radius) 0 0 var(--tf-border-radius);
  border-color: var(--tf-c-border);
  box-sizing: border-box;
  transition: border-color .225s var(--tf-transition-easing);
  width: 12px;
  height: 100%;
}

.notched-outline__notch {
  pointer-events: none;
  border-top: var(--tf-border-width-inactive) solid;
  border-bottom: var(--tf-border-width-inactive) solid;
  border-color: var(--tf-c-border);
  box-sizing: border-box;
  transition: border-color .225s var(--tf-transition-easing);
  flex: none;
  width: auto;
  max-width: calc(100% - 24px);
  height: 100%;
}

.input--focused .notched-outline__notch, .input--filled .notched-outline__notch, .textarea--focused .notched-outline__notch, .textarea--filled .notched-outline__notch {
  border-top: none;
  align-items: flex-start;
}

.notched-outline__trailing {
  pointer-events: none;
  border-top: var(--tf-border-width-inactive) solid;
  border-right: var(--tf-border-width-inactive) solid;
  border-bottom: var(--tf-border-width-inactive) solid;
  border-radius: 0 var(--tf-border-radius) var(--tf-border-radius) 0;
  border-left: none;
  border-color: var(--tf-c-border);
  box-sizing: border-box;
  transition: border-color .225s var(--tf-transition-easing);
  flex-grow: 1;
  height: 100%;
}

.input--focused .notched-outline__leading, .input--focused .notched-outline__notch, .input--focused .notched-outline__trailing, .input--filled .notched-outline__leading, .input--filled .notched-outline__notch, .input--filled .notched-outline__trailing, .textarea--focused .notched-outline__leading, .textarea--focused .notched-outline__notch, .textarea--focused .notched-outline__trailing, .textarea--filled .notched-outline__leading, .textarea--filled .notched-outline__notch, .textarea--filled .notched-outline__trailing {
  border-width: var(--tf-border-width-active);
}

.floating-label {
  right: initial;
  pointer-events: none;
  font-size: var(--tf-font-size-field);
  font-weight: var(--tf-font-weight);
  letter-spacing: var(--tf-letter-spacing);
  color: var(--tf-c-text-field);
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  transform-origin: 0 0;
  transition: transform .15s var(--tf-transition-easing), color .15s var(--tf-transition-easing);
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: text;
  max-width: calc(100% - 30px);
  line-height: 23px;
  display: inline-block;
  position: absolute;
  left: 17px;
  overflow: hidden;
}

.input .floating-label {
  top: 50%;
  transform: translateY(-50%);
}

.textarea .floating-label {
  top: 13px;
}

.input--focused .floating-label, .input--filled .floating-label {
  transform: translateY(-33px)scale(.75);
}

.input--focused.input--leading-icon .floating-label, .input--filled.input--leading-icon .floating-label {
  transform: translateY(-33px)translateX(-27px)scale(.75);
}

.input--focused.input--dense .floating-label, .input--filled.input--dense .floating-label {
  transform: translateY(-27px)scale(.75);
}

.input--focused.input--large .floating-label, .input--filled.input--large .floating-label {
  transform: translateY(-44px)scale(.75);
}

.input--focused.input--dense.input--leading-icon .floating-label, .input--filled.input--dense.input--leading-icon .floating-label {
  transform: translateY(-27px)translateX(-27px)scale(.75);
}

.input--focused.input--large.input--leading-icon .floating-label, .input--filled.input--large.input--leading-icon .floating-label {
  transform: translateY(-44px)translateX(-27px)scale(.75);
}

.textarea--focused .floating-label, .textarea--filled .floating-label {
  transform: translateY(-22px)scale(.75);
}

.input {
  will-change: transform;
  border-radius: var(--tf-border-radius) var(--tf-border-radius) 0 0;
  height: 48px;
  display: inline-flex;
  position: relative;
  overflow: visible;
}

.input input {
  z-index: 1;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  appearance: none;
  font-size: var(--tf-font-size-field);
  line-height: var(--tf-input-line-height);
  font-weight: var(--tf-font-weight);
  letter-spacing: var(--tf-letter-spacing);
  color: var(--tf-c-text-field);
  background: none;
  border: none;
  border-radius: 0;
  align-self: flex-end;
  width: 100%;
  height: 48px;
  padding: 0 15px;
  display: flex;
}

.input input:focus {
  outline: none;
}

.input--dense, .input--dense input {
  height: 36px;
}

.input--large, .input--large input {
  height: 70px;
}

.input:hover:not(.input--error, .input--disabled, .input--focused) {
  --tf-c-border: var(--tf-c-hover);
}

.input.input--leading-icon > .input__icon, .input.input--trailing-icon > .input__icon {
  fill: var(--tf-c-text-field);
}

.input--focused {
  --tf-c-border: var(--tf-c-focused);
}

.input--focused label {
  color: var(--tf-c-focused);
}

.input--focused input {
  caret-color: var(--tf-c-focused);
}

.input--disabled {
  --tf-c-border: var(--tf-c-disabled);
}

.input--disabled label, .input--disabled input, .input--disabled + .input-helper {
  color: var(--tf-c-disabled);
}

.input--disabled.input--leading-icon > .input__icon, .input--disabled.input--trailing-icon > .input__icon {
  fill: var(--tf-c-disabled);
}

.input--error {
  --tf-c-border: var(--tf-c-error);
}

.input--error input {
  caret-color: var(--tf-c-error);
}

.input--error label, .input--error + .text-field-helper {
  color: var(--tf-c-error);
}

.input--error label.floating-label--required:after {
  content: "*";
  margin-left: 1px;
  font-size: 18px;
  line-height: 0;
}

.input--error.input--leading-icon > .input__icon, .input--error.input--trailing-icon > .input__icon {
  fill: var(--tf-c-error);
}

.input .input__icon {
  width: 24px;
  height: 24px;
}

.input.input--leading-icon .input__icon, .input.input--trailing-icon .input__icon {
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.input.input--trailing-icon input {
  padding-left: 16px;
  padding-right: 48px;
}

.input.input--leading-icon input {
  padding-left: 48px;
  padding-right: 16px;
}

.input.input--leading-icon.input--trailing-icon input {
  padding-left: 48px;
  padding-right: 48px;
}

.input.input--trailing-icon .input__icon {
  left: initial;
  right: 12px;
}

.input.input--leading-icon .input__icon {
  left: 12px;
  right: initial;
}

.input.input--leading-icon.input--trailing-icon .input__icon ~ .input__icon {
  left: auto;
  right: 12px;
}

.input.input--leading-icon label {
  left: 44px;
  right: initial;
}

.input.input--trailing-icon label, .input.input--leading-icon label {
  max-width: calc(100% - 66px);
}

.input.input--leading-icon.input--trailing-icon label {
  max-width: calc(100% - 84px);
}

.textarea {
  will-change: transform;
  border-radius: var(--tf-border-radius) var(--tf-border-radius) 0 0;
  min-height: 80px;
  display: inline-flex;
  position: relative;
  overflow: visible;
}

.textarea textarea {
  resize: none;
  appearance: none;
  z-index: 1;
  font-size: var(--tf-font-size-field);
  line-height: var(--tf-textarea-line-height);
  font-weight: var(--tf-font-weight);
  letter-spacing: var(--tf-letter-spacing);
  color: var(--tf-c-text-field);
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  background: none;
  border: none;
  border-radius: 0;
  width: 100%;
  height: auto;
  margin: 8px 1px 2px 0;
  padding: 0 15px 4px;
  display: flex;
}

.textarea textarea:focus {
  outline: none;
}

.textarea:hover:not(.textarea--error, .textarea--disabled, .textarea--focused) {
  --tf-c-border: var(--tf-c-hover);
}

.textarea--focused {
  --tf-c-border: var(--tf-c-focused);
}

.textarea--focused label {
  color: var(--tf-c-focused);
}

.textarea--focused textarea {
  caret-color: var(--tf-c-focused);
}

.textarea--disabled {
  --tf-c-border: var(--tf-c-disabled);
}

.textarea--disabled label, .textarea--disabled textarea, .textarea--disabled + .textarea-helper {
  color: var(--tf-c-disabled);
}

.textarea--error {
  --tf-c-border: var(--tf-c-error);
}

.textarea--error textarea {
  caret-color: var(--tf-c-error);
}

.textarea--error label, .textarea--error + .text-field-helper {
  color: var(--tf-c-error);
}

.textarea--error label.floating-label--required:after {
  content: "*";
  margin-left: 1px;
  line-height: 0;
}

.text-field-helper {
  font-size: var(--tf-font-size-helper);
  color: var(--tf-c-text-helper);
  justify-content: flex-end;
  padding: 4px 17px 0;
  line-height: 1.3;
  display: flex;
}

.text-field-helper__text {
  flex: 1;
  padding-right: 10px;
}

.text-field-helper__counter {
  white-space: nowrap;
  text-align: right;
}

.text-field-container ::input-placeholder {
  color: var(--tf-c-placeholder);
  opacity: 0;
  transition: opacity .225s var(--tf-transition-easing);
}

.text-field-container :focus::input-placeholder {
  opacity: .65;
}

.text-field-container :input-placeholder {
  color: var(--tf-c-placeholder);
  opacity: 0;
  transition: opacity .225s var(--tf-transition-easing);
}

.text-field-container :focus:input-placeholder {
  opacity: .65;
}

.text-field-container ::placeholder {
  color: var(--tf-c-placeholder);
  opacity: 0;
  transition: opacity .225s var(--tf-transition-easing);
}

.text-field-container :focus::placeholder {
  opacity: .65;
}

.text-field-container :placeholder {
  color: var(--tf-c-placeholder);
  opacity: 0;
  transition: opacity .225s var(--tf-transition-easing);
}

.text-field-container :focus:placeholder {
  opacity: .65;
}

@supports (-webkit-overflow-scrolling: touch) {
  select, input[type="text"], textarea {
    font-size: 16px !important;
  }
}

.select-option {
  cursor: pointer;
  letter-spacing: .009375em;
  width: 100%;
  font-size: 16px;
  font-weight: 400;
  position: relative;
}

.select-option select {
  z-index: 1;
  opacity: 0;
  appearance: none;
  -webkit-tap-highlight-color: transparent;
  background-clip: padding-box;
  border: none;
  border-radius: 0;
  outline: none;
  margin: auto;
  position: absolute;
  inset: 0;
}

.select-option select:focus {
  -webkit-tap-highlight-color: transparent;
  border: none;
  outline: none;
}

.select-option:not(.select-option--mobile) select {
  display: none;
}

.select-option--opened {
  z-index: 2;
}

.select-option:after {
  pointer-events: none;
  content: "";
  background: var(--select-dropdown-arrow, url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0nMTAnIGhlaWdodD0nNScgdmlld0JveD0nNyAxMCAxMCA1JyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnPjxwYXRoIGZpbGwtcnVsZT0nZXZlbm9kZCcgb3BhY2l0eT0nLjU0JyBkPSdNNyAxMGw1IDUgNS01eicvPjwvc3ZnPg==")) center / 12px 12px no-repeat;
  width: 12px;
  height: 12px;
  margin: auto;
  display: block;
  position: absolute;
  inset: 0 14px 0 auto;
}

.select-option--opened:after {
  transform: rotate(.5turn);
}

.select-option .select-option-trigger {
  text-overflow: ellipsis;
  white-space: nowrap;
  color: var(--so-color-trigger, Gray);
  max-width: 100%;
  height: 48px;
  padding-left: 17px;
  padding-right: 34px;
  line-height: 48px;
  overflow: hidden;
}

.select-option.select-option--dense .select-option-trigger {
  height: 36px;
  line-height: 36px;
}

.select-option .select-option-list {
  z-index: 2;
  border-radius: var(--so-border-radius-list, 5.5px);
  background-color: var(--so-background-list, White);
  box-shadow: var(--so-list-shadow, 0 5px 5px -3px #0003, 0 8px 10px 1px #00000024, 0 3px 14px 2px #0000001f);
  width: 100%;
  max-height: 210px;
  margin-top: 4px;
  padding-top: 8px;
  padding-bottom: 8px;
  display: none;
  position: absolute;
  inset: 100% auto auto 0;
  overflow: hidden visible;
}

.select-option .select-option-list::-webkit-scrollbar {
  width: 12px;
}

.select-option .select-option-list::-webkit-scrollbar-thumb {
  background-color: var(--so-color-scrollbar-thumb, Silver);
  border: 4px solid var(--so-color-scrollbar-track, White);
}

.select-option .select-option-list::-webkit-scrollbar-thumb:hover {
  background-color: var(--so-color-scrollbar-hover, Gray);
}

.select-option .select-option-list::-webkit-scrollbar-track {
  background-color: var(--so-color-scrollbar-track, White);
}

.select-option.select-option--downstairs .select-option-list {
  margin-top: unset;
  margin-bottom: 12px;
  inset: auto auto 100% 0;
}

.select-option.select-option--opened .select-option-list {
  display: block;
}

.select-option .select-option-list-item {
  text-overflow: ellipsis;
  white-space: nowrap;
  background-color: var(--so-background-option, White);
  color: var(--so-color-list-item, Gray);
  height: 48px;
  padding-left: 17px;
  padding-right: 17px;
  line-height: 48px;
  overflow: hidden;
}

.select-option .select-option-list-item--selected {
  --so-background-option: var(--so-background-option-selected, DodgerBlue);
  --so-color-list-item: var(--so-color-list-item-selected, White);
}

.select-option .select-option-list-item:not(.select-option .select-option-list-item--selected):hover {
  --so-background-option: var(--so-background-option-hovered, WhiteSmoke);
}

.select-option {
  --so-width-outline: 1px;
}

.select-option--labeled .notched-outline {
  pointer-events: none;
  z-index: 1;
  grid-template: 1fr / 12px auto 1fr;
  gap: 0;
  height: 100%;
  margin: auto;
  display: grid;
  position: absolute;
  inset: 0;
}

.select-option--labeled .notched-outline__leading {
  box-shadow: inset 0 var(--so-width-outline) 0 0 var(--so-color-outline, DarkGray), inset var(--so-width-outline) 0 0 0 var(--so-color-outline, DarkGray), inset 0 calc(var(--so-width-outline) * -1) 0 0 var(--so-color-outline, DarkGray);
  border-radius: var(--so-border-radius-select, 5.5px) 0 0 var(--so-border-radius-select, 5.5px);
  transform: translate3d(0, 0, 0);
}

.select-option--labeled .notched-outline__notch {
  box-shadow: inset 0 var(--so-width-outline) 0 0 var(--so-color-outline, DarkGray), inset 0 calc(var(--so-width-outline) * -1) 0 0 var(--so-color-outline, DarkGray);
}

.select-option--labeled .notched-outline__trailing {
  box-shadow: inset 0 var(--so-width-outline) 0 0 var(--so-color-outline, DarkGray), inset calc(var(--so-width-outline) * -1) 0 0 0 var(--so-color-outline, DarkGray), inset 0 calc(var(--so-width-outline) * -1) 0 0 var(--so-color-outline, DarkGray);
  border-radius: 0 var(--so-border-radius-select, 5.5px) var(--so-border-radius-select, 5.5px) 0;
  transform: translate3d(0, 0, 0);
}

.select-option--labeled.select-option--opened .notched-outline__notch, .select-option--labeled.select-option--selected .notched-outline__notch {
  box-shadow: inset 0 calc(var(--so-width-outline) * -1) 0 0 var(--so-color-outline, DarkGray);
}

.select-option--unlabeled {
  box-shadow: inset 0 0 0 var(--so-width-outline) var(--so-color-outline, DarkGray);
  border-radius: var(--so-border-radius-select, 5.5px);
  transform: translate3d(0, 0, 0);
}

.select-option:not(.select-option--opened):hover {
  --so-color-outline: var(--so-color-outline-hover, SlateGrey);
}

.select-option:not(.select-option--opened):hover.select-option--mobile, .select-option--opened {
  --so-color-outline: var(--so-color-outline-opened, DodgerBlue);
  --so-width-outline: 2px;
}

.select-option .floating-label {
  pointer-events: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: var(--so-color-label, Gray);
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  transform-origin: 0 0;
  max-width: calc(100% - 51px);
  transition: transform .15s cubic-bezier(0, .202, .204, 1), color .15s cubic-bezier(0, .202, .204, 1);
  display: block;
  position: absolute;
  inset: 50% auto auto 17px;
  overflow: hidden;
  transform: translateY(-50%);
}

.select-option.select-option--selected .floating-label {
  --so-color-label: var(--so-color-label-selected, DarkGray);
  transform: translateY(-30px)scale(.75);
}

.select-option.select-option--opened .floating-label {
  --so-color-label: var(--so-color-label-opened, DodgerBlue);
  transform: translateY(-30px)scale(.75);
}

.select-option.select-option--opened.select-option--dense .floating-label, .select-option.select-option--selected.select-option--dense .floating-label {
  transform: translateY(-24px)scale(.75);
}

.tickbox {
  --tickbox-color-active-h: 210;
  --tickbox-color-active-s: 100%;
  --tickbox-color-active-l: 56%;
  --tickbox-color-active-a: 1;
  --tickbox-color-active: hsla(var(--tickbox-color-active-h) var(--tickbox-color-active-s) var(--tickbox-color-active-l) / var(--tickbox-color-active-a));
  --tickbox-color-inactive-h: 0;
  --tickbox-color-inactive-s: 0%;
  --tickbox-color-inactive-l: 66%;
  --tickbox-color-inactive-a: 1;
  --tickbox-color-inactive: hsla(var(--tickbox-color-inactive-h) var(--tickbox-color-inactive-s) var(--tickbox-color-inactive-l) / var(--tickbox-color-inactive-a));
  --tickbox-color-active-highlight: hsla(var(--tickbox-color-active-h) var(--tickbox-color-active-s) var(--tickbox-color-active-l) / 26%);
  --tickbox-color-inactive-highlight: hsla(var(--tickbox-color-inactive-h) var(--tickbox-color-inactive-s) var(--tickbox-color-inactive-l) / 26%);
  --tickbox-color-disabled: silver;
  --tickbox-transition-duration: .475s;
  --tickbox-transition-timing: cubic-bezier(.4, .08, .38, .9);
  --tickbox-label-font-size: 15px;
  --tickbox-desc-font-size: 12px;
  --tickbox-label-font-color: black;
  --tickbox-desc-font-color: gray;
  --tickbox-disabled-font-color: silver;
  pointer-events: none;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 2px 0;
  display: inline-flex;
  position: relative;
}

.tickbox input {
  pointer-events: auto;
  appearance: none;
  cursor: pointer;
  backface-visibility: hidden;
  -webkit-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  background: none;
  width: 48px;
  min-width: 48px;
  height: 48px;
  min-height: 48px;
  position: relative;
}

.tickbox input[type="checkbox"]:not([role="switch"]), .tickbox input[type="radio"]:not([role="switch"]) {
  margin-left: -14px;
  margin-right: 10px;
}

.tickbox input[role="switch"] {
  margin-left: -10px;
  margin-right: 15px;
}

.tickbox input:disabled {
  pointer-events: none;
  cursor: default;
}

.tickbox input:before, .tickbox input:after {
  content: "";
  margin: auto;
  position: absolute;
  inset: 0;
}

.tickbox--rtl {
  justify-content: space-between;
}

.tickbox--rtl input {
  order: 2;
}

.tickbox--rtl input[type="checkbox"]:not([role="switch"]), .tickbox--rtl input[type="radio"]:not([role="switch"]) {
  margin-left: 10px;
  margin-right: -14px;
}

.tickbox--rtl input[role="switch"] {
  margin-left: 15px;
  margin-right: -10px;
}

.tickbox--rtl label {
  order: 1;
}

.tickbox label {
  font-weight: var(--tickbox-label-font-weight, 400);
  line-height: 150%;
  font-size: var(--tickbox-label-font-size);
  color: var(--tickbox-label-font-color);
  letter-spacing: .01px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  user-select: none;
  pointer-events: auto;
  flex-direction: column;
  justify-content: center;
  max-width: 100%;
  display: inline-flex;
  position: relative;
  overflow: hidden;
}

.tickbox label span {
  padding: 1px 0;
}

.tickbox label span:first-child {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.tickbox label span + span {
  line-height: 125%;
  font-size: var(--tickbox-desc-font-size);
  color: var(--tickbox-desc-font-color);
  font-weight: var(--tickbox-desc-font-weight, 400);
}

.tickbox input:disabled + label {
  pointer-events: none;
  color: var(--tickbox-disabled-font-color);
}

.tickbox input:disabled + label span + span {
  color: var(--tickbox-disabled-font-color);
}

.tickbox input[type="checkbox"]:not(:disabled, [role="switch"]):focus, .tickbox input[type="radio"]:not(:disabled, [role="switch"]):focus {
  appearance: none;
  border-radius: 100px;
}

.tickbox input[type="checkbox"]:not(:disabled, [role="switch"]):focus:checked, .tickbox input[type="radio"]:not(:disabled, [role="switch"]):focus:checked {
  animation: rippleChecked var(--tickbox-transition-duration) var(--tickbox-transition-timing) forwards;
}

.tickbox input[type="checkbox"]:not(:disabled, [role="switch"]):focus:not(:checked), .tickbox input[type="radio"]:not(:disabled, [role="switch"]):focus:not(:checked) {
  animation: rippleUnchecked var(--tickbox-transition-duration) var(--tickbox-transition-timing) forwards;
}

@keyframes rippleChecked {
  from {
    box-shadow: inset 0 0 0 24px var(--tickbox-color-active-highlight);
  }

  to {
    box-shadow: inset 0 0 0 24px #0000;
  }
}

@keyframes rippleUnchecked {
  from {
    box-shadow: inset 0 0 0 24px var(--tickbox-color-inactive-highlight);
  }

  to {
    box-shadow: inset 0 0 0 24px #0000;
  }
}

.tickbox input[type="checkbox"]:not([role="switch"]):before, .tickbox input[type="checkbox"]:not([role="switch"]):after {
  --tickbox-checkbox: var(--tickbox-color-inactive);
  border-radius: var(--tickbox-checkbox-radius, 2px);
  border-color: var(--tickbox-checkbox);
  transition: border-color 90ms, background-color 90ms, transform .225s;
}

.tickbox input[type="checkbox"]:not([role="switch"]):before {
  border-width: var(--tickbox-border-width, 2px);
  border-style: solid;
  width: 20px;
  height: 20px;
}

.tickbox input[type="checkbox"]:not([role="switch"]):after {
  opacity: 0;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxOCIgaGVpZ2h0PSIxOCIgZmlsbD0iI2ZmZiI+PHBhdGggZD0ibTcgMTMuNC00LTRMNC40IDggNyAxMC42IDEzLjYgNCAxNSA1LjRsLTggOFoiLz48L3N2Zz4=");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 18px 18px;
  width: 20px;
  height: 20px;
  transform: rotate(-25deg);
}

.tickbox input[type="checkbox"]:not([role="switch"]):checked:before {
  --tickbox-checkbox: var(--tickbox-color-active);
  background-color: var(--tickbox-checkbox);
}

.tickbox input[type="checkbox"]:not([role="switch"]):checked:after {
  opacity: 1;
  transform: rotate(0);
}

.tickbox input[type="checkbox"]:not([role="switch"]):disabled:before, .tickbox input[type="checkbox"]:not([role="switch"]):disabled:after {
  --tickbox-checkbox: var(--tickbox-color-disabled);
}

.tickbox input[type="radio"]:not([role="switch"]):before, .tickbox input[type="radio"]:not([role="switch"]):after {
  --tickbox-radio: var(--tickbox-color-inactive);
  transition: border-color 90ms, background-color 90ms, transform 90ms;
}

.tickbox input[type="radio"]:not([role="switch"]):before {
  background-color: var(--tickbox-radio);
  border-radius: var(--tickbox-radio-mark-radius, 10px);
  width: 10px;
  height: 10px;
  transform: scale(0);
}

.tickbox input[type="radio"]:not([role="switch"]):after {
  border-width: var(--tickbox-border-width, 2px);
  border-style: solid;
  border-color: var(--tickbox-radio);
  border-radius: var(--tickbox-radio-border-radius, 20px);
  width: 20px;
  height: 20px;
}

.tickbox input[type="radio"]:not([role="switch"]):checked:before, .tickbox input[type="radio"]:not([role="switch"]):checked:after {
  --tickbox-radio: var(--tickbox-color-active);
}

.tickbox input[type="radio"]:not([role="switch"]):checked:before {
  transform: scale(1);
}

.tickbox input[type="radio"]:not([role="switch"]):disabled:before, .tickbox input[type="radio"]:not([role="switch"]):disabled:after {
  --tickbox-radio: var(--tickbox-color-disabled);
}

.tickbox input[role="switch"] {
  width: 55px;
  min-width: 55px;
}

.tickbox input[role="switch"]:before, .tickbox input[role="switch"]:after {
  --tickbox-switch-color: var(--tickbox-switch-color-inactive, var(--tickbox-color-inactive));
  border-color: var(--tickbox-switch-color);
  transition: border-color .125s, background-color .125s, transform .125s;
}

.tickbox input[role="switch"]:before {
  border-radius: var(--tickbox-switch-mark-radius, 10px);
  background-color: var(--tickbox-switch-color);
  width: 10px;
  height: 10px;
  transform: translateX(-6px);
}

.tickbox input[role="switch"]:after {
  border-radius: var(--tickbox-switch-border-radius, 24px);
  border-width: var(--tickbox-border-width, 2px);
  background-color: #0000;
  border-style: solid;
  width: 36px;
  height: 24px;
}

.tickbox input[role="switch"]:checked:before, .tickbox input[role="switch"]:checked:after {
  --tickbox-switch-color: var(--tickbox-switch-color-active, var(--tickbox-color-active));
}

.tickbox input[role="switch"]:checked:before {
  transform: translateX(6px)scale(1.4);
}

.tickbox input[role="switch"]:disabled:before, .tickbox input[role="switch"]:disabled:after {
  --tickbox-switch-color: var(--tickbox-switch-color-disabled, var(--tickbox-color-disabled));
}

:where(:not(html, iframe, canvas, img, svg, video, audio):not(svg *, symbol *)) {
  all: unset;
  display: revert;
}

*, :before, :after {
  box-sizing: border-box;
}

html {
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  text-size-adjust: none;
}

a, button {
  cursor: revert;
}

ol, ul, menu, summary {
  list-style: none;
}

ol {
  counter-reset: revert;
}

img {
  max-block-size: 100%;
  max-inline-size: 100%;
}

table {
  border-collapse: collapse;
}

input, textarea {
  -webkit-user-select: auto;
}

textarea {
  white-space: revert;
}

meter {
  appearance: revert;
}

:where(pre) {
  all: revert;
  box-sizing: border-box;
}

::placeholder {
  color: unset;
}

:where([hidden]) {
  display: none;
}

:where([contenteditable]:not([contenteditable="false"])) {
  -moz-user-modify: read-write;
  -webkit-user-modify: read-write;
  overflow-wrap: break-word;
  -webkit-line-break: after-white-space;
  -webkit-user-select: auto;
}

:where([draggable="true"]) {
  -webkit-user-drag: element;
}

:where(dialog:modal) {
  all: revert;
  box-sizing: border-box;
}

::-webkit-details-marker {
  display: none;
}

:root {
  --z-index-header: 1;
  --z-index-dialog-lite: 999;
  --c-white: #fff;
  --max-vw: 1600;
  --min-vw: 600;
  --max-fs: 1;
  --min-fs: .6;
  --font-family-base: "Inter", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --line-height-base: 1.5;
  --line-height-heading: 1.3em;
  --c-scrollbar-track: Black;
  --c-scrollbar-thumb: DarkGray;
  --c-scrollbar-hover: Brown;
  --v-offset-sm: min(50px, 3vmax);
  --v-offset-md: min(100px, 5vmax);
  --v-offset-lg: min(160px, 8vmax);
}

@media (width >= 992px) {
  :root {
    --v-offset-sm: min(40px, 2vmax);
    --v-offset-md: min(80px, 4vmax);
  }
}

html, body {
  position: relative;
}

body {
  background-image: url("page_bg.823cd930.jpg");
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100vw;
  overflow: hidden auto;
}

@media (width >= 1200px) {
  body {
    background-position: 50% 0;
  }
}

@supports (min-height: 100dvh) {
  body {
    min-height: 100dvh;
  }
}

@supports not (min-height: 100dvh) {
  body {
    min-height: 100vh;
  }
}

@media (width >= 1200px) {
  body:before {
    content: "";
    background-image: url("coin_two.6b136103.png"), url("coin_one.d4ef5993.png"), url("coin_three.3fb106e2.png"), url("banknote_two.593d03ec.png"), url("banknote_three.7eb60e43.png"), url("banknote_one.5dd4c542.png"), url("woman_two.992fa239.png"), url("woman_one.adfe301e.png");
    background-position: 33vw -140px, 83vw -20px, 89vw 60vh, calc(70% + 390px) calc(50% + 8vh), calc(30% - 390px) calc(50% + 8vh), calc(45% - 390px) calc(50% + 8vh), calc(60% + 390px) calc(50% + 8vh), calc(40% - 390px) calc(50% + 8vh);
    background-repeat: no-repeat, no-repeat, no-repeat, no-repeat, no-repeat, no-repeat, no-repeat, no-repeat;
    background-size: 300px 300px, 220px 240px, 200px 240px, 240px 240px, 240px 240px, 240px 240px, min(34.5vw, 690px) min(42vw, 840px), min(34.5vw, 690px) min(42vw, 840px);
    margin: auto;
    position: absolute;
    inset: 0;
  }
}

@media (width <= 1199.98px) {
  body:before {
    content: "";
    background-image: url("coin_two.6b136103.png"), url("coin_one.d4ef5993.png"), url("coin_three.3fb106e2.png"), url("woman_one.adfe301e.png"), url("woman_two.992fa239.png");
    background-position: calc(100% + 60px) -60px, calc(100% + 80px) calc(100% + 100px), -70px 60vh, -110px 42%, calc(100% + 110px) 42%;
    background-repeat: no-repeat, no-repeat, no-repeat, no-repeat, no-repeat;
    background-size: 160px 160px, 220px 240px, 160px 160px, 280px 340px, 280px 340px;
    margin: auto;
    position: absolute;
    inset: 0;
  }
}

body::-webkit-scrollbar {
  opacity: 0;
  width: 12px;
}

body::-webkit-scrollbar-thumb {
  background-color: var(--c-scrollbar-thumb, var(--c-canary-yellow));
  border: 4px solid var(--c-scrollbar-track, var(--c-blue));
  border-radius: 2px;
}

body::-webkit-scrollbar-thumb:hover {
  background-color: var(--c-scrollbar-hover, var(--c-text-greenish-yellow));
}

body::-webkit-scrollbar-track {
  background-color: var(--c-scrollbar-track, var(--c-blue));
}

.display-large {
  color: #a9a9a9;
  margin: 20px 0 0;
  font-weight: 900;
}

.page {
  z-index: 1;
  grid-template: auto auto auto auto auto / 1fr;
  align-content: space-between;
  gap: 0;
  width: min(1180px, 100%);
  max-width: 100vw;
  height: 100%;
  min-height: 100vh;
  margin: auto;
  display: grid;
  overflow: hidden;
}

@media (width >= 1200px) {
  .page {
    grid-template: auto auto 1fr auto / 1fr;
    padding: 0 20px;
    overflow: hidden;
  }
}

.page .page__logotype {
  transform: translateY(max(12px, 2vw));
}

@media (width >= 1200px) {
  .page .page__logotype {
    position: absolute;
    inset: 20px auto auto max(30px, 8.8vw);
    transform: none;
  }
}

.page .page__title {
  transform: translateY(-7%);
}

.page .page__wheel {
  width: min(1180px, 100% - 30px);
  margin: auto;
}

@media (width >= 1200px) {
  .page .page__wheel {
    margin: auto auto -230px;
  }
}

.page .page__gain {
  padding-top: 40px;
}

.page .page__payment {
  padding-top: 30px;
}

.svg-icon--logo-volta {
  aspect-ratio: 1;
  fill: #a9a9a9;
  width: clamp(160px, 22vmin, 300px);
}

.logotype-champion {
  --size-factor: 1.6;
  --c-logo-text-color: White;
  gap: 4px 8px;
  justify-items: unset;
  grid-template: auto / auto;
  width: fit-content;
  margin: auto;
  display: grid;
}

@media (width >= 768px) {
  .logotype-champion {
    justify-items: center;
    gap: 8px 0;
  }
}

.logotype-champion__star {
  width: calc(5em * var(--size-factor));
  aspect-ratio: 1;
}

@media (width <= 767.98px) {
  .logotype-champion__star {
    grid-area: 1 / 1 / 3 / 2;
  }
}

.logotype-champion__champion {
  height: calc(1.6em * var(--size-factor));
  aspect-ratio: 20 / 2.8;
  fill: var(--c-logo-text-color);
  align-self: end;
}

@media (width <= 767.98px) {
  .logotype-champion__champion {
    grid-area: 1 / 2 / 2 / 3;
  }
}

.logotype-champion__slots {
  height: calc(1.6em * var(--size-factor));
  aspect-ratio: 12 / 2.8;
  fill: var(--c-logo-text-color);
}

@media (width <= 767.98px) {
  .logotype-champion__slots {
    grid-area: 2 / 2 / 3 / 3;
  }
}

.wheel-fortune {
  --z-index-segments: 1;
  --z-index-blurring: 2;
  --z-index-blackout: 3;
  --z-index-button: 4;
  --z-index-border: 5;
  aspect-ratio: 1;
  width: min(480px, 100% - 40px);
  margin: auto;
  position: relative;
}

@media (width >= 1200px) {
  .wheel-fortune {
    width: min(540px, 100%);
  }
}

.wheel-fortune:after {
  pointer-events: none;
  z-index: 6;
  content: "";
  border-radius: 100%;
  border-radius: unset;
  filter: drop-shadow(0 0 clamp(5px, 1vmin, 20px) #000);
  background: url("wheel_arrow.d40fe66b.png") center / cover no-repeat;
  width: 64px;
  height: 64px;
  margin: auto;
  position: absolute;
  inset: 0 0 auto;
  transform: translateY(-64%);
}

@media (width >= 576px) {
  .wheel-fortune:after {
    width: 88px;
    height: 88px;
  }
}

.wheel-fortune:before {
  pointer-events: none;
  z-index: 6;
  content: "";
  border-radius: 100%;
  border-radius: unset;
  filter: drop-shadow(0 0 clamp(5px, 1vmin, 20px) #000);
  inset: 0;
  background: url("wheel_frame.f4121e07.png") center / cover no-repeat;
  margin: auto;
  position: absolute;
  inset: -7%;
}

.wheel-fortune__segments {
  z-index: var(--z-index-segments);
  will-change: transform;
  pointer-events: none;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 100%;
  margin: auto;
  position: absolute;
  inset: 0;
  overflow: hidden;
}

.wheel-fortune__decor {
  pointer-events: none;
  height: 100%;
}

.wheel-fortune__decor:after {
  z-index: var(--z-index-blurring);
  content: "";
  backdrop-filter: blur(var(--blurring));
  will-change: transform;
  backface-visibility: hidden;
  pointer-events: none;
  border-radius: 100%;
  margin: auto;
  position: absolute;
  inset: 0;
  transform: translate3d(0, 0, 0);
}

.wheel-fortune__decor:before {
  --cutout: calc(100% - (100% / var(--blackout-angle, 8)));
  --color: rgba(0, 0, 0, var(--blackout-opacity, 0));
  z-index: var(--z-index-blackout);
  content: "";
  background: conic-gradient(var(--color) var(--cutout), transparent var(--cutout));
  transform: rotate(calc(360deg / var(--blackout-angle, 8) / 2));
  pointer-events: none;
  border-radius: 100%;
  margin: auto;
  position: absolute;
  inset: 0;
}

.wheel-fortune__button {
  z-index: var(--z-index-button);
  aspect-ratio: 1;
  cursor: pointer;
  filter: drop-shadow(0 0 clamp(5px, .75vmin, 15px) #000000bf) brightness();
  background-image: url("wheel_button.c1e21d19.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 100%;
  width: 30%;
  margin: auto;
  transition: filter .475s;
  position: absolute;
  inset: 0;
}

.wheel-fortune.is-spinning .wheel-fortune__button, .wheel-fortune.end-last-spin .wheel-fortune__button {
  pointer-events: none;
  filter: drop-shadow(0 0 clamp(5px, .75vmin, 15px) #000000bf) brightness(.35);
}

.your-gain {
  grid-template: auto / 1fr 1fr;
  gap: 0;
  display: grid;
  position: relative;
}

.your-gain__title {
  filter: drop-shadow(0 0 clamp(5px, 1vmin, 20px) #000000bf);
  opacity: 0;
  grid-area: 1 / 1 / 2 / 3;
  margin-bottom: clamp(15px, 2.5vmin, 25px);
  transition: opacity .425s ease-in-out .225s;
}

body.first-spin-complete .your-gain__title {
  opacity: 1;
}

.your-gain__bonus-first {
  filter: drop-shadow(0 0 clamp(5px, 1vmin, 20px) #000000bf);
  grid-area: 2 / 1 / 3 / 2;
  justify-self: end;
  transition: transform 1s ease-in-out, opacity .525s ease-in-out;
}

@media (width >= 1200px) {
  .your-gain__bonus-first {
    opacity: 0;
    justify-self: start;
    transform: translateX(50%);
  }

  body.first-spin-complete .your-gain__bonus-first {
    opacity: 1;
    transform: translateX(0);
  }
}

@media (width <= 1199.98px) {
  .your-gain__bonus-first {
    opacity: 0;
    transform: translateX(50%);
  }

  body.first-spin-complete .your-gain__bonus-first {
    opacity: 1;
  }

  body.second-spin-complete .your-gain__bonus-first {
    transform: translateX(-10px);
  }
}

.your-gain__bonus-second {
  filter: drop-shadow(0 0 clamp(5px, 1vmin, 20px) #000000bf);
  grid-area: 2 / 2 / 3 / 3;
  justify-self: start;
  transition: transform 1s ease-in-out, opacity .525s ease-in-out;
}

@media (width >= 1200px) {
  .your-gain__bonus-second {
    opacity: 0;
    justify-self: end;
    transform: translateX(-50%);
  }

  body.second-spin-complete .your-gain__bonus-second {
    opacity: 1;
    transform: translateX(0);
  }
}

@media (width <= 1199.98px) {
  .your-gain__bonus-second {
    opacity: 0;
    transform: translateX(-50%);
  }

  body.second-spin-complete .your-gain__bonus-second {
    opacity: 1;
    transform: translateX(10px);
  }
}

.your-gain__stars {
  width: fit-content;
  height: fit-content;
  margin: auto;
  position: absolute;
  inset: 0;
}

@media (width <= 1199.98px) {
  body:not(.first-spin-complete) .your-gain__stars {
    animation: .75s ease-in-out infinite alternate libration;
    transform: rotate(-7deg);
  }

  @-webkit-keyframes libration {
    to {
      transform: rotate(7deg);
    }
  }
}

.your-gain__stars .svg-icon {
  width: 90px;
  height: 90px;
  transition: transform .225s ease-in-out, opacity .225s ease-in-out;
}

.your-gain__stars .svg-icon:first-child {
  margin: auto;
  position: absolute;
  inset: 0;
}

body.first-spin-complete .your-gain__stars .svg-icon:first-child {
  transform: translateX(-150px);
}

body.second-spin-complete .your-gain__stars .svg-icon:first-child {
  opacity: 0;
}

body.first-spin-complete .your-gain__stars .svg-icon:last-child {
  transform: translateX(150px);
}

body.second-spin-complete .your-gain__stars .svg-icon:last-child {
  opacity: 0;
}

.image-container {
  width: 100%;
  height: 100%;
}

.image-container picture {
  width: 100%;
  height: 100%;
  display: flex;
}

.image-container img {
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
}

.image-container--page-title {
  max-width: 620px;
  margin: auto;
}

.image-container--page-title img {
  object-fit: contain;
}

.image-container--your-bonus {
  max-width: 150px;
  margin: auto;
}

@media (width >= 1200px) {
  .image-container--your-bonus {
    margin: unset;
    max-width: 220px;
  }
}

.image-container--your-bonus img {
  object-fit: contain;
}

.image-container--bonus-1000fs {
  max-width: 150px;
}

@media (width >= 1200px) {
  .image-container--bonus-1000fs {
    max-width: 280px;
  }
}

.image-container--bonus-1000fs img {
  object-fit: contain;
}

.image-container--bonus-150000r {
  max-width: 150px;
}

@media (width >= 1200px) {
  .image-container--bonus-150000r {
    max-width: 280px;
  }
}

.image-container--bonus-150000r img {
  object-fit: contain;
}

.image-container--banner-first {
  border: 5px solid #feae3f;
  border-radius: 22px;
  overflow: hidden;
}

.image-container--banner-first img {
  vertical-align: middle;
}

.image-container--banner-second {
  border: 5px solid #feae3f;
  border-radius: 12px;
  margin-bottom: 18px;
  overflow: hidden;
}

.image-container--banner-second img {
  vertical-align: middle;
}

.payment-brands {
  backdrop-filter: blur(15px);
  background: #00003759;
  border-radius: 11px 11px 0 0;
  width: fit-content;
  margin: auto;
  display: grid;
}

.payment-brands__body {
  gap: 0 var(--v-offset-sm);
  padding: calc(var(--v-offset-sm) / 2) var(--v-offset-sm);
  grid-template: 1fr / repeat(6, 1fr);
  height: 100%;
  display: grid;
  position: relative;
}

@media (width >= 992px) {
  .payment-brands__body {
    padding: calc(var(--v-offset-sm) / 3) var(--v-offset-sm);
  }
}

.payment-brands__body .svg-icon {
  z-index: 1;
  aspect-ratio: 10 / 5.4;
  fill: #fff;
  width: 100%;
  max-width: 34px;
  position: relative;
}

@media (width >= 768px) {
  .payment-brands__body .svg-icon {
    fill: #fff;
    max-width: 52px;
  }
}

.dialog-lite {
  overflow: hidden;
}

.dialog-lite--in {
  overflow: hidden auto;
}

.modal-content {
  width: min(420px, 100%);
  margin: auto;
}

.modal-content__bait {
  cursor: pointer;
}

.dialog-lite--second .modal-content__bait {
  display: none;
}

.modal-content__form {
  background-color: #fff;
  border-radius: 22px;
  padding: 15px;
  overflow: hidden;
}

.dialog-lite--first .modal-content__form {
  display: none;
}

.form {
  gap: 20px 0;
  display: grid;
  position: relative;
}

.form input:-webkit-autofill {
  -webkit-text-fill-color: #000;
  -webkit-background-clip: text;
  transition: background-color 5000s ease-in-out;
  box-shadow: inset 0 0 20px 20px #0000;
}

.form input:-webkit-autofill:hover {
  -webkit-text-fill-color: #000;
  -webkit-background-clip: text;
  transition: background-color 5000s ease-in-out;
  box-shadow: inset 0 0 20px 20px #0000;
}

.form input:-webkit-autofill:focus {
  -webkit-text-fill-color: #000;
  -webkit-background-clip: text;
  transition: background-color 5000s ease-in-out;
  box-shadow: inset 0 0 20px 20px #0000;
}

.form input:-webkit-autofill:active {
  -webkit-text-fill-color: #000;
  -webkit-background-clip: text;
  transition: background-color 5000s ease-in-out;
  box-shadow: inset 0 0 20px 20px #0000;
}

.select-option {
  font-size: 16px;
}

.select-option--country .select-option-trigger, .select-option--country .select-option-list-item {
  padding-left: 54px;
}

.select-option--country .select-option-trigger--rub, .select-option--country .select-option-list-item--rub {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIxOCIgZmlsbD0ibm9uZSI+PHBhdGggZmlsbD0iI2ZmZiIgZmlsbC1ydWxlPSJldmVub2RkIiBkPSJNMCAwaDI0djZIMFYwWiIgY2xpcC1ydWxlPSJldmVub2RkIi8+PHBhdGggc3Ryb2tlPSIjRDBEMEQwIiBkPSJNLjUuNWgyM3YxN0guNVYuNVoiLz48cGF0aCBmaWxsPSIjMDAzOUE2IiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0wIDZoMjR2NkgwVjZaIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiLz48cGF0aCBmaWxsPSIjRDUyQjFFIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0wIDEyaDI0djZIMHYtNloiIGNsaXAtcnVsZT0iZXZlbm9kZCIvPjwvc3ZnPg==");
}

.select-option--country .select-option-trigger--kzt, .select-option--country .select-option-list-item--kzt {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIxOCIgZmlsbD0ibm9uZSI+PHBhdGggZmlsbD0iIzAxQTVDOCIgZD0iTTAgMGgyNHYxOEgweiIvPjxwYXRoIHN0cm9rZT0iI0ZGRTcwMCIgc3Ryb2tlLXdpZHRoPSIxLjI1IiBkPSJtOCAxMSA0LjUgM00xMS41IDE0bDQuNS0zIi8+PGNpcmNsZSBjeD0iMTIiIGN5PSI4IiByPSIzIiBmaWxsPSIjRkZFNzAwIi8+PC9zdmc+");
}

.select-option--country .select-option-trigger--rub, .select-option--country .select-option-trigger--kzt, .select-option--country .select-option-list-item--rub, .select-option--country .select-option-list-item--kzt {
  background-position: 18px;
  background-repeat: no-repeat;
  background-size: 24px 18px;
}

.auth-rules {
  --tickbox-checkbox-radius: 5px;
  margin: -10px 0;
  padding: 0;
  display: flex;
}

.auth-rules label {
  margin-left: -10px;
  font-size: 13px;
}

.auth-rules label span:first-child {
  white-space: unset;
  text-overflow: unset;
  overflow: auto;
}

.auth-rules a {
  color: #3b7ced;
}

.auth-rules a:hover {
  text-decoration: none;
}

.btn-signup-send {
  --button-color: White;
  --button-background: linear-gradient(to left, #ac09e7, #eb0291);
  background: var(--button-background);
  color: var(--button-color);
  box-sizing: border-box;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
  touch-action: manipulation;
  -webkit-user-select: none;
  user-select: none;
  letter-spacing: .0892857em;
  -webkit-tap-highlight-color: transparent;
  text-transform: uppercase;
  border: none;
  border-radius: 100px;
  outline: none;
  place-content: center;
  width: 100%;
  height: 48px;
  margin: 10px 0 0;
  padding: 0 22px;
  font-family: inherit;
  font-size: 16px;
  font-weight: 700;
  line-height: 1;
  text-decoration: none;
  display: grid;
  position: relative;
  overflow: hidden;
}

@media (hover: hover) and (pointer: fine) {
  .btn-signup-send:before {
    content: "";
    background: linear-gradient(to left, #eb0291, #ac09e7);
    margin: auto;
    transition: opacity .375s;
    position: absolute;
    inset: 0;
  }

  .btn-signup-send:hover:before {
    opacity: 0;
  }

  .btn-signup-send span {
    z-index: 1;
    position: relative;
  }
}
/*# sourceMappingURL=index.fbff0174.css.map */
