.btn-signup-send {
  --button-color: White;
  --button-background: linear-gradient(to left, #ac09e7, #eb0291);

  background: var(--button-background);
  color: var(--button-color);
  box-sizing: border-box;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
  touch-action: manipulation;
  -webkit-user-select: none;
  user-select: none;
  letter-spacing: 0.0892857143em;
  -webkit-tap-highlight-color: transparent;
  text-transform: uppercase;
  border: none;
  border-radius: 100px;
  outline: none;
  place-content: center;
  width: -moz-fit-content;
  width: 100%;
  height: 48px;
  margin: 10px 0 0;
  padding: 0 22px;
  font-family: inherit;
  font-size: 16px;
  font-weight: 700;
  line-height: 1;
  text-decoration: none;
  display: grid;
  position: relative;
  overflow: hidden;

  @media (hover: hover) and (pointer: fine) {
    &::before {
      content: '';
      position: absolute;
      inset: 0;
      margin: auto;
      background: linear-gradient(to left, #eb0291, #ac09e7);
      transition: opacity 375ms ease;
    }

    &:hover {
      &::before {
        opacity: 0;
      }
    }

    span {
      position: relative;
      z-index: 1;
    }
  }
}
