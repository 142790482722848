.select-option {
  font-size: 16px;

  &--country {
    .select-option-trigger,
    .select-option-list-item {
      padding-left: 54px;

      &--rub {
        background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIxOCIgZmlsbD0ibm9uZSI+PHBhdGggZmlsbD0iI2ZmZiIgZmlsbC1ydWxlPSJldmVub2RkIiBkPSJNMCAwaDI0djZIMFYwWiIgY2xpcC1ydWxlPSJldmVub2RkIi8+PHBhdGggc3Ryb2tlPSIjRDBEMEQwIiBkPSJNLjUuNWgyM3YxN0guNVYuNVoiLz48cGF0aCBmaWxsPSIjMDAzOUE2IiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0wIDZoMjR2NkgwVjZaIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiLz48cGF0aCBmaWxsPSIjRDUyQjFFIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0wIDEyaDI0djZIMHYtNloiIGNsaXAtcnVsZT0iZXZlbm9kZCIvPjwvc3ZnPg==);
      }

      &--kzt {
        background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIxOCIgZmlsbD0ibm9uZSI+PHBhdGggZmlsbD0iIzAxQTVDOCIgZD0iTTAgMGgyNHYxOEgweiIvPjxwYXRoIHN0cm9rZT0iI0ZGRTcwMCIgc3Ryb2tlLXdpZHRoPSIxLjI1IiBkPSJtOCAxMSA0LjUgM00xMS41IDE0bDQuNS0zIi8+PGNpcmNsZSBjeD0iMTIiIGN5PSI4IiByPSIzIiBmaWxsPSIjRkZFNzAwIi8+PC9zdmc+);
      }

      &--rub,
      &--kzt {
        background-position: center left 18px;
        background-size: 24px 18px;
        background-repeat: no-repeat;
      }
    }
  }
}
