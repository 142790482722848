.page {
  z-index: 1;
  display: grid;
  align-content: space-between;
  grid-template: auto auto auto auto auto / 1fr;
  gap: 0;
  margin: auto;
  width: min(1180px, 100%);
  height: 100%;
  min-height: 100vh;
  overflow: hidden;
  max-width: 100vw;

  @include min(tablet-landscape) {
    overflow: hidden;
    grid-template: auto auto 1fr auto / 1fr;
    padding: 0 20px;
  }

  .page__logotype {
    transform: translateY(max(12px, 2vw));

    @include min(tablet-landscape) {
      position: absolute;
      inset: 20px auto auto max(30px, 8.8vw);
      transform: none;
    }
  }

  .page__title {
    transform: translateY(-7%);
  }

  .page__wheel {
    margin: auto;
    width: min(1180px, 100% - 30px);

    @include min(tablet-landscape) {
      margin: auto auto -230px auto;
    }
  }

  .page__gain {
    padding-top: 40px;

    @include min(tablet-landscape) {

    }
  }

  .page__payment {
    padding-top: 30px;
  }
}
