.image-container {
  width: 100%;
  height: 100%;

  picture {
    display: flex;
    width: 100%;
    height: 100%;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  &--page-title {
    margin: auto;
    max-width: 620px;

    img {
      object-fit: contain;
    }
  }

  &--your-bonus {
    max-width: 150px;
    margin: auto;

    @include min(tablet-landscape) {
      margin: unset;
      max-width: 220px;
    }

    img {
      object-fit: contain;
    }
  }

  &--bonus-1000fs {
    max-width: 150px;

    @include min(tablet-landscape) {
      max-width: 280px;
    }

    img {
      object-fit: contain;
    }
  }

  &--bonus-150000r {
    max-width: 150px;

    @include min(tablet-landscape) {
      max-width: 280px;
    }

    img {
      object-fit: contain;
    }
  }

  &--banner-first {
    overflow: hidden;
    border: 5px solid #FEAE3F;
    border-radius: 22px;

    img {
      vertical-align: middle;
    }
  }

  &--banner-second {
    overflow: hidden;
    margin-bottom: 18px;
    border: 5px solid #FEAE3F;
    border-radius: 12px;

    img {
      vertical-align: middle;
    }
  }
}
